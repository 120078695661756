import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter } from "react-router";
import { RouterProvider } from "react-router/dom";

import "./fonts.css";
import "./index.css";
import "./sentry";
import "./supertokens";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

function convert(m: any) {
  let { clientLoader, clientAction, default: Component, ...rest } = m;
  return {
    ...rest,
    loader: clientLoader,
    action: clientAction,
    Component,
  };
}

const router = createBrowserRouter([
  {
    path: "/fetcher/reset",
    lazy: () => import("./fetcher_reset").then(convert),
  },
  {
    lazy: () => import("./posthog").then(convert),
    children: [
      {
        path: "/download",
        lazy: () => import("./routes/download").then(convert),
      },
      {
        path: "/terms",
        lazy: () => import("./routes/terms").then(convert),
      },
      {
        path: "/privacy",
        lazy: () => import("./routes/privacy").then(convert),
      },
      {
        path: "/*",
        lazy: () => import("./routes/login").then(convert),
      },
      {
        lazy: () => import("./routes/root").then(convert),
        children: [
          {
            path: "/profile/create",
            lazy: () => import("./routes/profile/create").then(convert),
          },
          {
            id: "profilecheck",
            lazy: () => import("./routes/profile/check").then(convert),
            children: [
              {
                path: "/",
                lazy: () => import("./routes/home_redirect").then(convert),
              },
              {
                path: "/home",
                lazy: () => import("./routes/home").then(convert),
              },
              {
                path: "/profile",
                lazy: () => import("./routes/profile/view").then(convert),
              },
              {
                path: "/profile/edit",
                lazy: () => import("./routes/profile/edit").then(convert),
              },
              {
                path: "/friends",
                lazy: () => import("./routes/friends/list").then(convert),
              },
              {
                path: "/friends/add",
                lazy: () => import("./routes/friends/add").then(convert),
              },
              {
                path: "/friends/fulfil",
                lazy: () => import("./routes/friends/fulfil").then(convert),
              },
              {
                path: "/friends/create",
                lazy: () => import("./routes/friends/create").then(convert),
              },
              {
                path: "/games/create",
                lazy: () =>
                  import("./routes/games/create/game_wizard").then(convert),
              },
              {
                path: "/games/:gameID",
                children: [
                  {
                    path: "scorecard-template",
                    lazy: () =>
                      import("./routes/games/create/scorecard_wizard").then(
                        convert,
                      ),
                  },
                  {
                    path: "groups/create",
                    lazy: () =>
                      import("./routes/games/create/group_wizard").then(
                        convert,
                      ),
                  },
                  {
                    id: "gameroot",
                    path: "groups/:groupID",
                    lazy: () =>
                      import("./routes/games/group_root").then(convert),
                    children: [
                      {
                        path: "permissions",
                        lazy: () =>
                          import("./routes/games/group_permissions").then(
                            convert,
                          ),
                      },
                      {
                        path: "trackers/:trackerID",
                        lazy: () =>
                          import("./routes/games/trackers/edit").then(convert),
                      },
                      {
                        path: "trackers/:trackerID/celebrate",
                        lazy: () =>
                          import("./routes/games/trackers/celebrate").then(
                            convert,
                          ),
                      },
                      {
                        path: "trackers",
                        lazy: () =>
                          import("./routes/games/trackers/leaderboard").then(
                            convert,
                          ),
                      },
                      {
                        path: "trackers/stats",
                        lazy: () =>
                          import("./routes/games/trackers/stats").then(convert),
                      },
                      {
                        path: "trackers/history",
                        lazy: () =>
                          import("./routes/games/trackers/history").then(
                            convert,
                          ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  capture_pageview: false,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <RouterProvider router={router} />
    </PostHogProvider>
  </React.StrictMode>,
);
